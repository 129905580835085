

















import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    RadioToggle,
  },
})
export default class LessonProgressSetting extends Vue {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private isEdit = false

  private row = 0

  private items: { id: number; name: string; checked: boolean }[] = []

  @Prop()
  isGroup!: boolean

  private changeEditMode(): void {
    this.isEdit = true
  }
  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas() {
    // 授業方針情報を取得
    await Vue.prototype.$http.httpWithToken
      .get(`/classPolicies`, {
        params: {
          branchId: this.branchId,
          isGroup: this.isGroup,
        },
      })
      .then((res: any) => {
        this.items = res.data.map(
          (classInfo: { id: number; classCategoryName: string; sortNum: number; isEnabled: boolean }) => {
            return {
              id: classInfo.id,
              name: classInfo.classCategoryName,
              checked: classInfo.isEnabled,
            }
          }
        )
      })
  }
  private async submitDatas() {
    Vue.prototype.$loading.start()
    await this.patchClassPolicies()
    Vue.prototype.$loading.complete()
  }

  private async patchClassPolicies() {
    const classPolycies = []
    for (let i = 0; i < this.items.length; i++) {
      classPolycies.push({
        id: this.items[i].id,
        sortNum: i + 1,
        isEnabled: this.items[i].checked,
      })
    }
    await Vue.prototype.$http.httpWithToken
      .patch(`/classPolicies`, {
        branchId: this.branchId,
        isGroup: this.isGroup,
        classPolicies: classPolycies,
      })
      .then(() => {
        alert('更新しました。')
        this.isEdit = false
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('全項目をなしにすることは出来ません。')
        } else {
          alert('エラーが発生しました。操作をやり直してください。')
        }
      })
  }
}
