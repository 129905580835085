














import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    ModalBase,
  },
})
export default class ModalLessonPrivateSetting extends Mixins(ModalBaseMethod) {
  private komaOptionDatas: { text: string; value: number; disabled: boolean }[] = []

  private komaSelectedData = 0

  private editData = {}

  public showEdit(data: any): void {
    this.editData = data
    const options = []
    for (let i = 1; i < 16; i++) {
      options.push({
        text: `${i}`,
        value: i,
        disabled: data['maxUsingPeriod'] > i,
      })
    }
    this.komaOptionDatas = options
    this.komaSelectedData = data['period']
    this.show()
  }
  public save(): void {
    // 変更保存
    Vue.prototype.$http.httpWithToken
      .patch(`/private_class_settings/${this.editData['id']}`, {
        periodNum: this.komaSelectedData,
      })
      .then(() => {
        alert('更新しました。')
      })
      .catch(() => {
        alert('更新に失敗しました。')
      })
      .finally(() => {
        this.$emit('save-finished')
        this.hide()
      })
  }
}
