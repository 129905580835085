import { render, staticRenderFns } from "./Lesson.vue?vue&type=template&id=70e2c1cb&scoped=true&lang=pug&"
import script from "./Lesson.vue?vue&type=script&lang=ts&"
export * from "./Lesson.vue?vue&type=script&lang=ts&"
import style0 from "./Lesson.vue?vue&type=style&index=0&id=70e2c1cb&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e2c1cb",
  null
  
)

export default component.exports