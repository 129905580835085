









































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import WeekCodeMethods from '@/components/atoms/WeekCodeMethods.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    CheckboxSquare,
    ModalBase,
  },
})
export default class ModalLessonGroupSetting extends Mixins(ModalBaseMethod, WeekCodeMethods) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private gradeOptionDatas: { text: string; value: number }[] = []

  private gradeSelectedData = 0

  private editData: {
    id: number
    grade: string
    class: string
    weekCodes: string[]
  } | null = null

  private classOptionDatas: { text: string; value: number }[] = []

  private classSelectedData = 0

  private weekDatas: object[] = []

  private isEdit = false
  private validated = false

  public async showConfirm() {
    this.isEdit = false
    this.editData = null
    this.validated = false
    for (const weekdata of this.weekDatas) {
      weekdata['checked'] = false
    }
    this.show()
  }

  public async showEdit(data: any) {
    this.isEdit = true
    this.editData = data
    this.validated = true
    for (const weekdata of this.weekDatas) {
      weekdata['checked'] = data.weekCodes.includes(weekdata['code'])
    }
    this.show()
  }

  private created() {
    this.setWeekDatas()
  }

  private setWeekDatas() {
    this.weekDatas = this.weekCodeList()
  }

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    // 学年プルダウンの情報設定
    Vue.prototype.$http.httpWithToken.get(`/grades`).then((res: any) => {
      this.gradeOptionDatas = res.data.map((grade: { id: number; name: string; code: string; sortNum: string }) => {
        return {
          text: grade.name,
          value: grade.id,
        }
      })
    })
    // クラスプルダウンの情報設定
    Vue.prototype.$http.httpWithToken
      .get(`/group_classes`, { params: { branchId: this.branchId } })
      .then((res: any) => {
        this.classOptionDatas = res.data.groupClasses.map((group: { id: number; name: string }) => {
          return {
            text: group.name,
            value: group.id,
          }
        })
      })
  }

  private save(): void {
    if (!this.validCheck()) {
      return
    }
    if (this.isEdit) {
      if (!this.editData) {
        return
      }
      // 変更保存
      Vue.prototype.$http.httpWithToken
        .patch(`/group_class_settings/${this.editData.id}`, {
          weekCodes: this.selectedWeekCodes(),
        })
        .then(() => {
          alert('更新しました。')
        })
        .catch((error: any) => {
          if (error.response.data.status === 404) {
            alert('データが見つかりません。ページを更新してお確かめください。')
          }
        })
        .finally(() => {
          this.$emit('save-finished')
          this.hide()
        })
    } else {
      // 新規登録
      Vue.prototype.$http.httpWithToken
        .post(`/group_class_settings`, {
          branchId: this.branchId,
          gradeId: this.gradeSelectedData,
          groupClassId: this.classSelectedData,
          weekCodes: this.selectedWeekCodes(),
        })
        .then(() => {
          alert('登録しました。')
        })
        .catch((error: any) => {
          if (error.response.data.status === 409) {
            alert('学年・教科・クラスの組み合わせが既に存在するため登録できません。')
          }
        })
        .finally(() => {
          this.$emit('save-finished')
          this.hide()
        })
    }
  }

  private validCheck(): boolean {
    let valid = true

    valid =
      valid &&
      this.weekDatas.some((week) => {
        return week['checked']
      })
    if (!this.isEdit) {
      valid = valid && !!this.classSelectedData
      valid = valid && !!this.gradeSelectedData
    }
    this.validated = valid
    return valid
  }

  private selectedWeekCodes(): string[] {
    return this.weekDatas
      .filter((week) => {
        return week['checked']
      })
      .map((week) => {
        return week['code']
      })
  }
}
