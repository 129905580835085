











import { Component, Mixins, Vue, Ref } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import WeekCodeMethods from '@/components/atoms/WeekCodeMethods.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LessonProgressSetting from '@/components/organisms/LessonProgressSetting.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalLessonGroupSetting from '@/components/organisms/ModalLessonGroupSetting.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    LessonProgressSetting,
    TableBase,
    ModalLessonGroupSetting,
  },
})
export default class LessonGroupSetting extends Mixins(WeekCodeMethods) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private tableBaseItems: {
    week: string
    grade: string
    subject: string
    class: string
    action: any[]
  }[] = []

  private tableBaseFields: object[] = [
    { key: 'grade', label: '学年' },
    { key: 'class', label: 'クラス' },
    { key: 'week', label: '曜日' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons: string[] = ['action']

  private isEdit = false

  @Ref() modalLessonGroupSetting!: ModalLessonGroupSetting

  private showLessonGroupSetting(): void {
    this.modalLessonGroupSetting.showConfirm()
  }

  private showLessonGroupEditSetting(data: any): void {
    this.modalLessonGroupSetting.showEdit(data)
  }

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    // 集団授業情報を取得
    Vue.prototype.$http.httpWithToken
      .get(`/group_class_settings`, {
        params: {
          branchId: this.branchId,
        },
      })
      .then((res: any) => {
        this.tableBaseItems = res.data.groupClassSettings.map(
          (classSetting: {
            id: number
            gradeName: string
            groupClassName: string
            isUsing: boolean
            weekCodes: string[]
          }) => {
            return {
              grade: classSetting.gradeName,
              class: classSetting.groupClassName,
              week: this.weekCodesToTexts(classSetting.weekCodes).join('・'),
              action: [
                {
                  onclick: this.showLessonGroupEditSetting,
                  name: '曜日編集',
                  variable: {
                    id: classSetting.id,
                    grade: classSetting.gradeName,
                    class: classSetting.groupClassName,
                    weekCodes: classSetting.weekCodes,
                  },
                },
                {
                  onclick: this.remove,
                  name: '削除',
                  variable: { id: classSetting.id, isUsing: classSetting.isUsing },
                },
              ],
            }
          }
        )
      })
  }

  private remove(data: any): void {
    if (data['isUsing']) {
      alert('生徒が紐付いているため削除できません。')
      return
    }
    if (!confirm('削除しますか？')) return

    // 削除
    Vue.prototype.$http.httpWithToken
      .delete(`/group_class_settings/${data['id']}`)
      .then(() => {
        alert('削除しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 422) {
          alert('生徒が紐付いているため削除できません。')
        } else if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
      .finally(() => {
        this.loadDatas()
      })
  }

  private savedLessonGroupSetting(): void {
    this.loadDatas()
  }
}
