










import { Component, Ref, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import WeekCodeMethods from '@/components/atoms/WeekCodeMethods.vue'
import LessonProgressSetting from '@/components/organisms/LessonProgressSetting.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ModalLessonPrivateSetting from '@/components/organisms/ModalLessonPrivateSetting.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    LessonProgressSetting,
    TableBase,
    ModalLessonPrivateSetting,
  },
})
export default class LessonPrivateSetting extends Mixins(WeekCodeMethods) {
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  private tableBaseItems: {
    week: string
    period: number
    action: any[]
  }[] = []

  private tableBaseFields: object[] = [
    { key: 'week', label: '曜日' },
    { key: 'period', label: 'コマ数' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons: string[] = ['action']

  @Ref() modalLessonPrivateSetting!: ModalLessonPrivateSetting

  private showLessonPrivateSetting(data: any): void {
    this.modalLessonPrivateSetting.showEdit(data)
  }

  private mounted() {
    this.loadDatas()
  }

  private async loadDatas() {
    // 集団授業情報を取得
    Vue.prototype.$http.httpWithToken
      .get(`/private_class_settings`, {
        params: {
          branchId: this.branchId,
        },
      })
      .then((res: any) => {
        this.tableBaseItems = res.data.map(
          (classSetting: { id: number; weekCode: string; periodNum: number; maxUsingPeriod: number }) => {
            return {
              week: this.weekCodeToText(classSetting.weekCode),
              period: classSetting.periodNum,
              action: [
                {
                  onclick: this.showLessonPrivateSetting,
                  name: '編集',
                  variable: {
                    id: classSetting.id,
                    period: classSetting.periodNum,
                    maxUsingPeriod: classSetting.maxUsingPeriod,
                  },
                },
              ],
            }
          }
        )
      })
  }

  private savedLessonPrivateSetting(): void {
    this.loadDatas()
  }
}
