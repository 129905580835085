










import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import LessonPrivateSetting from '@/components/organisms/LessonPrivateSetting.vue'
import LessonGroupSetting from '@/components/organisms/LessonGroupSetting.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    LessonPrivateSetting,
    LessonGroupSetting,
  },
})
export default class Lesson extends Vue {
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '授業', active: true },
  ]

  private tabBaseDatas = [
    { id: 1, name: '集団' },
    { id: 2, name: '個別' },
  ]

  private isActiveTab = 1
}
